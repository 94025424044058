import { Divider, Stack, Typography } from '@mui/material';
import styles from '../latest-ads/styles';
import SectionsContainer from 'containers/home/sections-container';
import { useRouter } from 'next/router';

type Props = {
  prices: PopularCarDetailsWithImage[];
};
const PopularCarPrice = ({ prices }: Props) => {
  const { push } = useRouter();
  return (
    <SectionsContainer
      showMoreLink="/car-price"
      title="قیمت روز"
      subtitle="پرطرفدارها"
    >
      {prices.map((price) => {
        const dif = price.change;

        return (
          <Stack
            onClick={() => {
              push(`/car-price/${price.brand_en.toLowerCase()}`);
            }}
            sx={styles.adItem}
            className="embla__slide"
            key={`${price.brand_model}-${price.price}`}
          >
            <Stack
              flexDirection="column"
              justifyContent="start"
              alignItems="center"
              padding="0.75rem"
              width="100%"
            >
              <Typography sx={styles.adTitle} width="100%">
                {price.name_fa}
              </Typography>
              <Stack
                width="100%"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                mt="0.5rem"
              >
                <Typography sx={styles.carAttrBox}>{price.year}</Typography>
                {/*<Typography sx={styles.carAttrBox}>{price.brand_fa}</Typography>*/}
                <Typography sx={styles.carAttrBox}>
                  {price.mileage === 0
                    ? 'صفر'
                    : `${price.mileage.toLocaleString()} km`}
                </Typography>
              </Stack>
              <Divider
                sx={{ width: '100%', marginY: '0.75rem' }}
                variant="middle"
              />
              <Stack
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
                gap="0.25rem"
                alignItems="center"
              >
                {dif !== 0 ? (
                  <Typography
                    sx={{
                      ...styles.priceDif,
                      color: (t) => {
                        if (!dif || dif === 0)
                          return t.palette.iconDefault.main;
                        if (dif > 0) {
                          return t.palette.success.main;
                        } else {
                          return t.palette.error.main;
                        }
                      },
                    }}
                  >
                    {`${Math.abs(dif)}%${dif > 0 ? '+' : '-'}`}
                  </Typography>
                ) : (
                  <Typography sx={styles.priceDif}>0%</Typography>
                )}
                <Typography sx={styles.priceTitle}>
                  {price.price.toLocaleString()}
                  {' ت'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </SectionsContainer>
  );
};
export default PopularCarPrice;
