import { Button, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import priceImage from '../../../../../public/assets/images/home/car-price.webp';
import ENV from '../../../../constants/environments';
import styles from '../styles';

const DesktopCarPrice = () => {
  const { replace } = useRouter();
  return (
    <Stack sx={styles.desktopContainer}>
      <Stack
        width="43rem"
        flexDirection="column"
        justifyContent="center"
        alignItems="start"
      >
        <Typography sx={styles.desktopDescription}>
          با ثبت مشخصات کامل خودرو، از حدود قیمت و ارزش واقعی آن مطلع شوید.
        </Typography>
        {/* <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
          <FormControl sx={styles.formControl}>
            <CarSearchTextFeild
              vehicleType="light_car"
              size="small"
              onChange={() => {
                // setSelectedBrand(newCar);
                // setData(initialData);
                // formik.setFieldValue('brandModel', newCar?.name);
                // formik.setFieldValue('manufacturedYear', '');
                // formik.setFieldValue('usage', '');
              }}
            />
          </FormControl>
        </Stack> */}
        <Button
          onClick={() => replace(`${ENV.LANDING_URL}/car-price/used-car`)}
          sx={styles.submitButton}
        >
          تخمین قیمت
        </Button>
      </Stack>
      <Stack
        sx={{
          width: '16.3rem',
          height: 'auto',
          position: 'relative',
        }}
      >
        <Image
          sizes="100%"
          style={{
            aspectRatio: 786 / 498,
          }}
          src={priceImage}
          alt="car-price-image"
        />
      </Stack>
    </Stack>
  );
};
export default DesktopCarPrice;
