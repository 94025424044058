import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { HomeHeroProps } from 'containers/home/hero/desktop';
import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { karnamehServices } from 'layout/Header/data';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import useTwaApp from '../../../../hooks/useTwaApp';
import theme from '../../../../theme';
import emblaStyle from './embla.module.css';
import styles from './styles';

const MobileHero = ({ banners }: HomeHeroProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    direction: 'rtl',
    align: 'center',
    loop: true,
    containScroll: 'keepSnaps',
  });
  const scrollToNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi],
  );

  const onSelect = useCallback((api: EmblaCarouselType) => {
    setCurrentIndex(api.selectedScrollSnap());
  }, []);

  const { isTwa } = useTwaApp();

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSelect);
      setInterval(() => {
        scrollToNext();
      }, 8000);
    }
  }, [emblaApi, onSelect]);

  const { push } = useRouter();

  return (
    <Stack
      sx={{
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
        gap: '0',
      }}
      width="100%"
      direction="column"
    >
      <Stack className={emblaStyle.embla} sx={styles.embla}>
        <Stack className={emblaStyle.embla__viewport} ref={emblaRef}>
          <Stack
            sx={styles.embla__container}
            className={emblaStyle.embla__container}
          >
            {banners.map((item) => (
              <Stack
                onClick={() => {
                  if (item.redirect_url) {
                    const url = new URL(item.redirect_url);
                    if (url.searchParams.get('utm_source') && isTwa)
                      url.searchParams.set('utm_source', 'twa');
                    push(url.toString());
                  }
                }}
                key={`mobile-slider-${item.id}`}
                sx={styles.embla__slide}
                className={emblaStyle.embla__slide}
              >
                <Stack
                  className={emblaStyle.embla__slide__img}
                  sx={{
                    width: '19.5rem',
                    height: '10.985rem',
                    position: 'relative',
                    borderRadius: '0.5rem',
                  }}
                >
                  <Image
                    alt={`mobile-slider-${item.id}`}
                    style={{
                      borderRadius: '12px',
                    }}
                    src={item.mobile_url}
                    layout="fill"
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        flexDirection="row"
        width="100%"
        justifyContent="center"
        alignItems="center"
        gap="0.3rem"
        bgcolor="white"
        pt="0.5rem"
      >
        {banners.length > 1 &&
          banners.map((item, index) => {
            return (
              <Box
                key={index}
                borderRadius="99rem"
                onClick={() => {
                  if (index === currentIndex) return;
                  scrollTo(index);
                }}
                width={index === currentIndex ? '1rem' : '0.4rem'}
                height="0.4rem"
                sx={{
                  backgroundColor: () =>
                    currentIndex === index
                      ? theme.palette.iconDefault.main
                      : theme.palette.iconDeactive.main,
                }}
              />
            );
          })}
      </Stack>
      <Stack sx={styles.servicesContainer}>
        <Grid container sx={styles.gridContainer} spacing={0} rowSpacing={1.5}>
          {karnamehServices
            .sort((a, b) => {
              return a.id - b.id;
            })
            .map((item, index) => {
              const Icon = item.icon;
              if (index < 8 || showMore)
                return (
                  <Grid
                    sx={styles.servicesGrid}
                    xs={3}
                    item
                    key={`karnameh-service-${item.id}`}
                    onClick={() => {
                      if (!item.link) return;
                      push(item.link);
                    }}
                  >
                    <Link
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ textDecoration: 'none' }}
                    >
                      <Stack
                        sx={{
                          width: '3rem',
                          height: '3rem',
                          backgroundColor: '#EBF8F1',
                          borderRadius: '0.5rem',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {Icon && <Icon type="component" size="24" />}
                      </Stack>
                      <Typography
                        maxWidth="5rem"
                        sx={{
                          ...styles.serviceTitle,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Link>
                  </Grid>
                );
            })}
        </Grid>
        {karnamehServices.length > 8 && (
          <Stack
            sx={styles.showMoreContainer}
            onClick={() => setShowMore((prevState) => !prevState)}
          >
            <Typography sx={styles.showMoreText}>
              {showMore ? 'کمتر' : 'بیشتر'}
            </Typography>
            {showMore ? (
              <KeyboardArrowUpIcon sx={styles.showMoreIcon} />
            ) : (
              <KeyboardArrowDownIcon sx={styles.showMoreIcon} />
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
export default MobileHero;
