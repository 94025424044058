import { Stack } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTwaApp from '../../../hooks/useTwaApp';

type Props = {
  banner: BannerItem;
};

const HomeBanner = ({ banner }: Props) => {
  const { push } = useRouter();
  const { isTwa } = useTwaApp();

  return (
    <Stack
      onClick={() => {
        if (banner.redirect_url) {
          const url = new URL(banner.redirect_url);
          if (url.searchParams.get('utm_source') && isTwa)
            url.searchParams.set('utm_source', 'twa');
          push(url.toString());
        }
      }}
      sx={{
        width: '100%',
        height: 'auto',
        marginTop: '.5rem',
        padding: '1.5rem',
        backgroundColor: 'white',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          height: 'calc(100vw / (850 / 337))',
          position: 'relative',
          borderRadius: '0.5rem',
          overflow: 'hidden',
        }}
      >
        <Image
          sizes="100%"
          layout="fill"
          src={banner.mobile_url}
          objectFit="cover"
          alt="captured-image"
          style={{
            aspectRatio: 850 / 337,
          }}
        />
      </Stack>
    </Stack>
  );
};
export default HomeBanner;
