import { Box } from '@mui/material';
import AdsItem from 'components/RelatedItems/AdsItem';
import SectionsContainer from 'containers/home/sections-container';
import style from './styles';

type Props = {
  ads: HomeAdData[];
  title?: string;
  action?: string;
};

const LatestAds = ({
  ads,
  title = 'تازه‌ترین آگهی‌ها',
  action = '/buy-used-cars',
}: Props) => {
  return (
    <SectionsContainer
      showMoreLink={action}
      title={title}
      subtitle="پرطرفدارها"
    >
      {ads.map((ad, index) => {
        return (
          <Box sx={style.adItem} key={index} className="embla__slide">
            <AdsItem {...ad} />
          </Box>
        );
      })}
    </SectionsContainer>
  );
};
export default LatestAds;
