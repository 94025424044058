import createStyle from 'utils/createStyle';
import theme from '../../../../theme';

const styles = createStyle({
  container: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    // marginTop: '4rem',
    backgroundColor: 'white',
    padding: 0,
    paddingBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  carouselContainer: {
    width: '100%',
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    // background: 'linear-gradient(180deg, #FDBE31 0%, #FDBE31 100%)',
  },
  slider: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  emblaContainer: {
    width: '100%',
    paddingY: '0rem',
  },
  gridContainer: {
    paddingY: '1.5rem',
    paddingX: '1rem',
    justifyContent: 'start',
  },
  servicesGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
  },
  serviceTitle: {
    marginTop: '0.25rem',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0.025rem',
    textAlign: 'center',
    verticalAlign: 'center',
    color: (t) => t.palette.text.primary,
  },
  serviceLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  showMoreContainer: {
    gap: '0.5rem',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '0.5rem',
    cursor: 'pointer',
  },
  showMoreText: {
    color: (t) => t.palette.iconDefault.main,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0.025rem',
  },
  showMoreIcon: {
    width: '1.25rem',
    height: '1.25rem',
    color: (t) => t.palette.iconDefault.main,
  },
});

export default styles;
