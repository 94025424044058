import createStyle from 'utils/createStyle';

const styles = createStyle({
  container: {
    width: '100vw',
    height: '100dvh',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    padding: 0,
  },
  embla: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
  },
  embla__container: {
    display: 'flex',
    flexDirection: 'row',
  },
  embla__slide: {
    flex: '0 0 100%',
    minWidth: '0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 4px',
  },
  sliceImageContainer: {
    width: '100%',
    height: '9.25rem',
    borderRadius: '0.5rem',
    position: 'relative',
    marginBottom: '60px',
  },
  servicesContainer: {
    width: '100vw',
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1rem',
  },
  gridContainer: {
    width: '100vw',
    padding: '1.5rem',
    justifyContent: 'start',
  },
  servicesGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
  },
  serviceTitle: {
    marginTop: '0.25rem',
    fontSize: '0.687rem',
    fontWeight: 400,
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
    textAlign: 'center',
    verticalAlign: 'center',
    color: (theme) => theme.palette.text.primary,
  },
  showMoreContainer: {
    gap: '0.5rem',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem 1rem 1rem',
    cursor: 'pointer',
  },
  showMoreText: {
    color: (theme) => theme.palette.iconDefault.main,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0.025rem',
  },
  showMoreIcon: {
    width: '1.25rem',
    height: '1.25rem',
    color: (theme) => theme.palette.iconDefault.main,
    position: 'relative',
    top: '-2px',
  },
});

export default styles;
