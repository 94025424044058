import createStyle from 'utils/createStyle';

const styles = createStyle({
  container: {
    width: '100%',
    position: 'sticky',
    bottom: 0,
    padding: '16px',
  },
  innerContainer: {
    position: 'relative',
    aspectRatio: { xs: '300 / 250', md: '464 / 105' },
    overflow: 'hidden',
    borderRadius: '8px',
  },
  closeButton: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    top: '8px',
    right: '8px',
    background: (th) => th.palette.grey['700'],
    ':hover': {
      background: (th) => th.palette.grey['800'],
    },
  },
});

export default styles;
