import { Stack, Typography } from '@mui/material';
import styles from './styles';
import Image from 'next/image';
import SectionsContainer from '../sections-container';
import notFoundImage from '../../../../public/assets/images/home/home-empty.webp';
import { useRouter } from 'next/router';

type Props = {
  articles: BlogPost[];
};

const Articles = ({ articles }: Props) => {
  const { push } = useRouter();

  const formatDate = (date: string): string => {
    return new Date(date).toLocaleDateString('fa-IR').toString();
  };

  return (
    <SectionsContainer
      title="تازه‌های خودرو"
      subtitle=""
      showMoreLink="https://karnameh.com/blog/"
    >
      {articles.map((article) => {
        return (
          <Stack
            onClick={() => {
              push(article.url);
            }}
            sx={styles.articleItem}
            className="embla__slide"
            key={article.id}
          >
            <Stack
              sx={{
                width: '100%',
                height: '7.87rem',
                position: 'relative',
              }}
            >
              <Image
                sizes="100%"
                layout="fill"
                src={
                  article.cover_image === null ||
                  article.cover_image === undefined ||
                  article.cover_image.length === 0
                    ? notFoundImage
                    : article.cover_image
                }
                objectFit="cover"
                alt="article-image"
              />
            </Stack>
            <Stack
              flexDirection="column"
              padding="0.75rem"
              width="100%"
              gap="0.7rem"
            >
              <Typography sx={styles.articleTitle}>{article.title}</Typography>
              <Typography sx={styles.articleSubtitle}>
                {formatDate(article.published_at)}
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </SectionsContainer>
  );
};
export default Articles;
