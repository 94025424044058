import Head from 'next/head';
import { FC } from 'react';
import { PageHeadLinksInterface } from 'interfaces/page.interface';
import ENV from 'constants/environments';

const MetaTags: FC<PageHeadLinksInterface> = ({
  title = '',
  description = '',
  robots,
  canonical = '',
  viewport,
  children,
}) => {
  return (
    <Head key={title}>
      {children ? children : null}
      {title ? <title>{title}</title> : null}
      {description ? <meta name="description" content={description} /> : null}
      {viewport ? <meta name="viewport" content={viewport} /> : null}
      {robots ? (
        ENV.APP_ENV.includes('test') ? (
          <meta name="robots" content="NOINDEX,NOFOLLOW" />
        ) : (
          <meta name="robots" content={robots} />
        )
      ) : null}
      {canonical ? <link rel="canonical" href={canonical} /> : null}
    </Head>
  );
};

export default MetaTags;
