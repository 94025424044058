import { Stack, Typography, useMediaQuery } from '@mui/material';
import styles from './styles';
import theme from 'theme';
import MobileCarPrice from 'containers/home/used-car-price/mobile';
import DesktopCarPrice from 'containers/home/used-car-price/desktop';

const UsedCarPrice = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Stack sx={styles.container}>
        <Typography sx={styles.headingTitle}>قیمت خودروی کارکرده</Typography>

        {isMobile ? <MobileCarPrice /> : <DesktopCarPrice />}
      </Stack>
    </>
  );
};
export default UsedCarPrice;
