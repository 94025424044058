import createStyle from 'utils/createStyle';
import t from 'theme';

const styles = createStyle({
  container: {
    width: '100%',
    marginTop: '.5rem',
    bgcolor: 'white',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    padding: '1.5rem',
    [t.breakpoints.up('md')]: {
      paddingX: '9.25rem',
      marginTop: '0',
    },
  },
  desktopContainer: {
    flexDirection: 'row',
    width: '100%',
    height: '15.25rem',
    borderRadius: '1.5rem',
    paddingY: '2.87rem',
    paddingX: '2rem',
    marginTop: '2rem',
    gap: '5rem',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '3.5rem',
    backgroundColor: (theme) => theme.palette.background.default,
  },
  desktopDescription: {
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1,5rem',
    color: (theme) => theme.palette.iconDefault.main,
  },
  mobileDescription: {
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: '1,5rem',
    marginTop: '.75rem',
    color: (theme) => theme.palette.iconDefault.main,
  },
  headingTitle: {
    fontSize: '1.375rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
    color: (theme) => theme.palette.text.primary,
  },
  headingSubTitle: {
    fontSize: '.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    color: (theme) => theme.palette.iconDefault.main,
    marginTop: '.025rem',
    marginBottom: '1.5rem',
  },
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
  formControl: {
    flexGrow: 1,
  },
  startAndormentIcon: {
    color: '#6E6E6E',
    marginRight: 1,
  },
  submitButton: {
    width: '100%',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    color: 'white',
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
    ':hover': {
      backgroundColor: (theme) => theme.palette.primary.main,
    },
    backgroundColor: (theme) => theme.palette.primary.main,
    ':disabled': {
      color: '#A9A8AA',
      backgroundColor: '#EEEEEE',
    },
    [t.breakpoints.up('sm')]: {
      width: '14rem',
      backgroundColor: (theme) => theme.palette.primary.main,
      color: 'white',
    },
  },
});

export default styles;
