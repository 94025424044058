import * as Sentry from '@sentry/nextjs';
import { useCallback, useEffect, useRef } from 'react';
import { useSendUserAction } from 'services/rest/userLogging';
import { getUserPhone } from 'utils/auth';

const useTrace = (
  serviceName: SendUserActionParams['service_name'],
  eventName: string,
  initialLog: boolean = true,
  configs?: {
    eventType: SendUserActionParams['event_type'];
    eventMetaData: SendUserActionParams['event_metadata'];
  },
) => {
  const { mutateAsync: sendUserAction } = useSendUserAction();
  const initialLogHappened = useRef(false);

  const trace = useCallback(() => {
    return sendUserAction({
      event_type: configs?.eventType || 'view',
      service_name: serviceName,
      event_name: eventName,
      event_metadata: configs?.eventMetaData || {},
    })
      .then((res) => {
        if (getUserPhone() === '09162943105') {
          Sentry.captureMessage(`matching test success ${getUserPhone()}`);
        }
        return res;
      })
      .catch(() => {
        if (getUserPhone() === '09162943105') {
          Sentry.captureMessage(`matching test error ${getUserPhone()}`);
        }
      });
  }, [configs, eventName, sendUserAction, serviceName]);

  useEffect(() => {
    if (initialLog && !initialLogHappened.current) {
      trace();
      initialLogHappened.current = true;
    }
  }, [initialLog, trace]);

  return trace;
};

export default useTrace;
