import t from 'theme';
import createStyle from 'utils/createStyle';

const styles = createStyle({
  articleItem: {
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '16.9375rem',
    flexShrink: 0,
    height: 'auto',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    border: '1px solid #EBEBEB',
    cursor: 'pointer',
    [t.breakpoints.up('md')]: {
      width: '25%',
    },
  },
  articleTitle: {
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1rem',
    width: '100%',
    height: '2rem',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    color: (theme) => theme.palette.text.primary,
  },
  articleSubtitle: {
    fontSize: '0.625rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
    letterSpacing: '0.00625rem',
    color: (theme) => theme.palette.iconDefault.main,
  },
});

export default styles;
