import t from 'theme';
import createStyle from 'utils/createStyle';

const styles = createStyle({
  container: {
    width: '100%',
    marginTop: '.5rem',
    bgcolor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5rem',
    [t.breakpoints.up('md')]: {
      paddingX: '7.25rem',
      marginTop: '0',
    },
  },
  headingTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: '1.75rem',
    lineHeight: '1.75rem',
    color: (theme) => theme.palette.text.primary,
  },
  headingSubTitle: {
    fontSize: '0.6875rem',
    fontWeight: 600,
    marginBottom: '1.75rem',
    lineHeight: '1.25rem',
    color: (theme) => theme.palette.iconDefault.main,
  },
  showMore: {
    fontSize: '0.75rem',
    fontWeight: 600,
    marginBottom: '1.75rem',
    lineHeight: '1.25rem',
    color: (theme) => theme.palette.primary.main,
  },
  adsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    overflowY: 'hidden',
    overflowX: 'scroll',
    gap: '1rem',
  },
  adItem: {
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '11.8rem',
    flexShrink: 0,
    height: 'auto',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    border: '1px solid #EBEBEB',
    cursor: 'pointer',
    [t.breakpoints.up('md')]: {
      width: '25%',
    },
  },
  adTitle: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
    letterSpacing: '0.00625rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'start',
    overflow: 'hidden',
    color: (theme) => theme.palette.text.primary,
  },
  carAttrBox: {
    padding: '0 0.25rem',
    borderRadius: '0.5rem',
    fontSize: '0.625rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    color: (theme) => theme.palette.iconDefault.main,
    backgroundColor: (theme) => theme.palette.background.default,
  },
  priceTitle: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0.025',
    color: (theme) => theme.palette.text.primary,
  },
  priceDif: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: '0.025',
  },
  latestPriceTime: {
    fontSize: '0.625rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    letterSpacing: '0.00625',
    color: (theme) => theme.palette.iconDefault.main,
  },
  sliderControllerRight: {
    border: '1px solid #EBEBEB',
    width: '2.5rem',
    height: '4rem',
    borderRadius: '0',
    borderTopRightRadius: '0.75rem',
    borderBottomRightRadius: '0.75rem',
  },
  sliderControllerLeft: {
    border: '1px solid #EBEBEB',
    width: '2.5rem',
    height: '4rem',
    borderRadius: '0',
    borderTopLeftRadius: '0.75rem',
    borderBottomLeftRadius: '0.75rem',
  },
});

export default styles;
