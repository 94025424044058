import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { BannerContents } from 'services/rest/dynamic-contents/dynamic-contents';
import styles from './styles';
import Image from 'next/image';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface PropTypes extends BannerContents {
  idName: string;
}

const Banner: FC<PropTypes> = (props) => {
  const {
    name,
    desktop_url: desktopUrl,
    mobile_url: mobileUrl,
    redirect_url: redirectUrl,
    idName,
  } = props;

  const theme = useTheme();
  const isBiggerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const [closed, setClosed] = useState(false);

  if (closed) {
    return null;
  }

  return (
    <Stack sx={styles.container}>
      <Stack
        id={idName}
        component="a"
        href={redirectUrl}
        sx={styles.innerContainer}
      >
        {isBiggerThanMd && (
          <Image layout="fill" objectFit="cover" src={desktopUrl} alt={name} />
        )}
        {!isBiggerThanMd && (
          <Image layout="fill" objectFit="cover" src={mobileUrl} alt={name} />
        )}
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setClosed(true);
          }}
          size="small"
          sx={styles.closeButton}
        >
          <CloseRoundedIcon
            sx={{
              color: (th) => th.palette.surface.default,
              width: '20px',
              height: '20px',
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Banner;
