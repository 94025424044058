import createStyle from 'utils/createStyle';
import theme from 'theme';

const styles = createStyle({
  container: {
    width: '100%',
    marginTop: '0.5rem',
    bgcolor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.5rem',
  },
  sliderControllerRight: {
    width: '2.5rem',
    height: '4rem',
    borderRadius: '0',
    marginTop: '3rem',
    borderTopRightRadius: '0.75rem',
    borderBottomRightRadius: '0.75rem',
  },
  sliderControllerLeft: {
    width: '2.5rem',
    height: '4rem',
    borderRadius: '0',
    marginTop: '3rem',
    borderTopLeftRadius: '0.75rem',
    borderBottomLeftRadius: '0.75rem',
  },
  headingTitle: {
    fontSize: '1.375rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
    color: (t) => t.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  headingSubTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
    color: (t) => t.palette.iconDefault.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.6875rem',
    },
  },
  showMore: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
    cursor: 'pointer',
    color: (t) => t.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  scrollContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '1rem',
  },
});

export default styles;
