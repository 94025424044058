import { Stack, Typography } from '@mui/material';
import Banner from 'containers/car-price/components/Search/Contents/Banner';
import SectionsContainer from 'containers/home/sections-container';
import Image from 'next/image';
import { FC } from 'react';
import { secondsToString } from 'utils/timeUtils';
import { KarnamaVideoItem } from '../../../../services/rest/karnama/karnama';
import AppLink from '../../../../components/AppLink';

type Props = {
  videoList: KarnamaVideoItem[];
  headingTitle: string;
  headingAction: string;
  actionLink?: string;
};

const VideoListSection: FC<Props> = ({
  videoList,
  headingAction,
  headingTitle,
  actionLink,
}) => {
  return (
    <SectionsContainer
      subtitle={headingAction}
      title={headingTitle}
      showMoreLink={actionLink}
    >
      {videoList.map((video) => {
        return (
          <AppLink
            style={{}}
            key={video.id}
            href={`/karnama/details/${video.id}`}
          >
            <Stack
              sx={{
                backgroundColor: (t) => t.palette.background.default,
                padding: '1rem',
                borderRadius: '0.5rem',
                textDecoration: 'none',
                color: (t) => t.palette.text.primary,
                cursor: 'pointer',
              }}
              width="14.5rem"
              minWidth="14.5rem"
              height="14.5rem"
              className="embla__slide"
            >
              <Stack
                sx={{
                  width: '200px',
                  height: '114px',
                  position: 'relative',
                  padding: 0,
                }}
              >
                <Image
                  width={200}
                  height={114}
                  alt={video.title}
                  style={{
                    padding: '0',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    inset: '0',
                    borderRadius: '2px',
                  }}
                  src={video.cover ?? Banner}
                />
                <Typography
                  bgcolor="#454545"
                  paddingY="0"
                  paddingX="0.5rem"
                  position="absolute"
                  mx="0.5rem"
                  mb="0.25rem"
                  right="0"
                  bottom="0"
                  fontSize="0.75rem"
                  lineHeight="1.5rem"
                  color="white"
                  fontWeight="bold"
                  borderRadius="0.5rem"
                  sx={{
                    opacity: '95%',
                  }}
                >
                  {secondsToString(video.duration)}
                </Typography>
              </Stack>
              <Typography
                fontSize="0.75rem"
                lineHeight="1.5rem"
                mt="0.75rem"
                overflow="hidden"
                noWrap
                dangerouslySetInnerHTML={{ __html: video.title }}
              />

              {/*<Typography*/}
              {/*  sx={{*/}
              {/*    whiteSpace: 'nowrap',*/}
              {/*    overflow: 'hidden',*/}
              {/*    textOverflow: 'ellipsis',*/}
              {/*  }}*/}
              {/*  fontSize="0.75rem"*/}
              {/*  lineHeight="1.5rem"*/}
              {/*  dangerouslySetInnerHTML={{ __html: video.description }}*/}
              {/*/>*/}

              <Stack
                direction="row"
                justifyContent="start"
                justifyItems="center"
                gap="0.25rem"
                color={(t) => t.palette.iconDefault.main}
              >
                <Typography fontSize="0.625rem" lineHeight="1.25rem">
                  {`${video.visit_count.toLocaleString()} بازدید`}
                </Typography>
                <Typography fontSize="0.625rem" lineHeight="1.25rem">
                  .
                </Typography>
                <Typography fontSize="0.625rem" lineHeight="1.25rem">
                  {new Date(video.create_datetime).toLocaleDateString(
                    'fa-IR-u-nu-latn',
                    {
                      month: 'long',
                      year: 'numeric',
                    },
                  )}
                </Typography>
              </Stack>
            </Stack>
          </AppLink>
        );
      })}
    </SectionsContainer>
  );
};
export default VideoListSection;
