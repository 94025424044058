import { Button, Stack, Typography } from '@mui/material';
import ENV from 'constants/environments';
import { useRouter } from 'next/router';
import styles from '../styles';

const MobileCarPrice = () => {
  const { replace } = useRouter();
  // const [isBrandsBSOpen, setIsBrandsBSOpen] = useState<boolean>(false);
  // const [selectedBrandModel, setSelectedBrandModel] = useState<Car | undefined>(
  //   undefined,
  // );

  // const isItemCar = (
  //   item: string | CitiesV1List200DataItem | Car,
  // ): item is Car => {
  //   return typeof item === 'object' && 'name_en' in item;
  // };

  return (
    <>
      <Stack
        width="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {/* <Stack sx={{ ...styles.inputContainer, alignItems: 'start' }}>
          <TextField
            sx={{
              ...styles.input,
              '& .MuiInputBase-root.Mui-disabled': {
                '& > fieldset': {
                  borderColor: theme.palette.surface.paper,
                },
              },
              '& .MuiOutlinedInput-root': {
                paddingRight: '6px',
                paddingLeft: '6px',
              },
              '& .MuiOutlinedInput-input': {
                paddingLeft: '8px',
                paddingRight: '8px',
              },
            }}
            size="small"
            // value={selectedBrandModel?.name || ''}
            InputProps={{
              placeholder: 'جستجوی برند / مدل / تیپ',
              readOnly: true,
              startAdornment: (
                <SearchRoundedIcon
                  sx={{
                    ...styles.startAndormentIcon,
                    marginRight: '0',
                    marginLeft: 0.5,
                  }}
                />
              ),
            }}
            inputProps={{
              'aria-autocomplete': 'none',
              autoComplete: 'nope',
            }}
          />
        </Stack> */}
        <Typography sx={styles.mobileDescription}>
          با ثبت مشخصات کامل خودرو، از حدود قیمت و ارزش واقعی آن مطلع شوید.
        </Typography>
        <Button
          onClick={() => replace(`${ENV.LANDING_URL}/car-price/used-car`)}
          sx={styles.submitButton}
        >
          تخمین قیمت
        </Button>
      </Stack>
      {/* <CarDetailsBottomSheet
        onItemClicked={(item) => {
          if (isItemCar(item)) {
            setSelectedBrandModel(item);
            setIsBrandsBSOpen(false);
          }
        }}
        onClose={() => setIsBrandsBSOpen(false)}
        contentType={isBrandsBSOpen ? 'brand' : undefined}
        shouldPrefetchBrands={false}
        showSuggestedBadgeBrands={true}
        vehicleType="light_car"
      /> */}
    </>
  );
};
export default MobileCarPrice;
