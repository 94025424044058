export const secondsToTime = (seconds: number): TimeObject => {
  const hours = Math.floor(seconds / (60 * 60));
  const divisorForMinutes = seconds % (60 * 60);
  const divisorForSeconds = divisorForMinutes % 60;
  const minutes = Math.floor(divisorForMinutes / 60);
  const sec = Math.ceil(divisorForSeconds);
  return {
    hours,
    minutes,
    seconds: sec,
  };
};

export const timeObjectToString = (time: TimeObject) => {
  const h = time.hours < 10 ? `0${time.hours}` : time.hours.toString();
  const m = time.minutes < 10 ? `0${time.minutes}` : time.minutes.toString();
  const s = time.seconds < 10 ? `0${time.seconds}` : time.seconds.toString();
  if (h === '00') {
    return `${m}:${s}`;
  }
  return `${h}:${m}:${s}`;
};

export const secondsToString = (secnods: number) => {
  const time = secondsToTime(secnods);
  return timeObjectToString(time);
};

type TimeObject = {
  hours: number;
  minutes: number;
  seconds: number;
};
