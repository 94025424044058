import theme from 'theme';
import createStyle from 'utils/createStyle';

const styles = createStyle({
  container: {
    maxWidth: '100%',
    height: 'auto',
    paddingBottom: '1.5rem',
    backgroundColor: (t) => t.palette.background.default,
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '90.75rem',
      marginX: 'auto',
    },
  },
});

export default styles;
